<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { relationTypeList } from '@/utils/textFile'
import { getAction } from '@/command/netTool'
import { Rate } from 'ant-design-vue'
export default {
  name: 'evaluate',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/base/farmEvaluate/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '评论人',
          type: 'input',
          key: 'nickname',
        },
        {
          name: '评价时间',
          type: 'rangePicker',
          keys: ['evaluateStartDate', 'evaluateEndDate'],
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id',
        },
      ]
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'nickname',
          title: '评论人',
          align: 'left',
          customRender: function (text, records) {
            return (
              <div class="product-order-item">
                <img src={records.headImg} />
                <div>{records.nickname}</div>
              </div>
            )
          },
        },
        {
          dataIndex: 'relationType',
          title: '评论来源',
          align: 'left',
          onExport: (records) => (relationTypeList.find((e) => e.value == records) || { name: '' }).name,
          filterMultiple: false,
          filters: relationTypeList,
          customRender: (records) => (relationTypeList.find((e) => e.value == records) || { name: '' }).name,
        },
        {
          dataIndex: 'content',
          title: '评论内容',
          align: 'left',
          sorter: (a, b) => {
            if (a.content && b.content) {
              return Number(a.content.length) - Number(b.content.length)
            }
          },
          customRender: function (text, records) {
            return <div class="content-box">{text}</div>
          },
        },
        {
          dataIndex: 'img',
          title: '评论图片',
          type: 'image',
          align: 'left',
          customRender: function (text, records) {
            let imgArr = text ? text.split(',') : []
            return <div class="product-order-item">{text ? imgArr.map((r) => <img src={r} />) : ''}</div>
          },
        },
        {
          dataIndex: 'createDate',
          title: '评价时间',
          align: 'left',
          sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/base/farmEvaluate?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/base/farmEvaluate/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    getTableColumns(data) {
      const columns = [].concat(data.list)
      const arr = [
        {
          name: `V${data.memberLevel}优惠`,
          value: '-￥' + data.memberDiscountAmount,
          display: data.memberDiscountAmount,
        },
        {
          name: '运费',
          value: '￥' + data.freightAmount,
          display: data.freightAmount && data.relationType === '3',
        },
        {
          name: '运费',
          value: '包邮',
          display: !data.freightAmount && data.relationType === '3',
        },
        {
          name: '优惠券',
          //   name: `优惠券${data.couponName}`,
          value: '-￥' + data.couponAmount,
          display: data.couponAmount,
        },
        {
          name: '余额券',
          value: '￥' + data.balanceAmount,
          display: data.balanceAmount,
        },
        {
          name: '实付金额',
          value: '￥' + data.payAmount,
          display: data.payAmount,
        },
      ].filter((e) => e.display)
      arr.forEach((e) => {
        columns.push({
          productQuantity: e.name,
          totalPrice: e.value,
        })
      })
      return columns
    },
    getDrawerForm(records) {
      const form = [
        {
          display: !['4', '5', '6'].includes(records.relationType),
          class: 'order-table',
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: this.getTableColumns(records),
              showPagination: false,
              columns: [
                {
                  title: '商品',
                  type: 'lt-200',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (!records.productPic || !records.productName) return null
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <img
                          src={records.productPic}
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                          }}
                        />
                        <div
                          style={{
                            maxWidth: '260px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {records.productName}
                        </div>
                      </div>
                    )
                  },
                },
                {
                  title: '规格',
                  dataIndex: 'productAttr',
                  type: 'ct-100',
                  display: records.relationType === '3',
                },
                {
                  title: '床类型',
                  dataIndex: 'productAttr',
                  type: 'ct-100',
                  display: records.relationType === '8',
                },
                {
                  title: '数量',
                  dataIndex: 'productQuantity',
                  type: 'ct-100',
                  display: true,
                },
                {
                  title: '总价',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (records.totalPrice) return records.totalPrice
                    return <div>￥ {records.productQuantity * records.productPrice}</div>
                  },
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          title: '基础信息',
          display: records.createDate,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '评论来源',
                  value: records.relationName,
                  display: records.relationName,
                },
                {
                  name: '单号',
                  value: records.list[0]?.orderSn || '',
                  display: records?.list[0]?.orderSn || false,
                },
                {
                  name: '评论来源',
                  value: relationTypeList.filter((e) => e.value === records.relationType)[0].name,
                  display: records.relationType,
                },
                {
                  name: '评论人',
                  value: records.nickname,
                  display: records.nickname,
                },
                {
                  name: '描述相符',
                  valueRender: () => {
                    return <Rate defaultValue={records.evaluate[0]?.descriptionScore || 0} disabled />
                  },
                  display: records.evaluate[0]?.descriptionScore || false,
                },
                {
                  name: '评论时间',
                  value: records.createDate,
                  display: records.createDate,
                },
                {
                  name: '服务体验',
                  valueRender: () => {
                    return <Rate defaultValue={records.evaluate[0]?.serviceScore || 0} disabled />
                  },
                  display: records.evaluate[0]?.serviceScore || false,
                },
                {
                  name: '评论店铺',
                  value: records.shopName,
                  display: records.shopName,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          title: '内容',
          display: records.content,
          form: [
            {
              type: 'text',
              value: records.content,
              display: records.content,
            },
            {
              type: 'imageGroup',
              value: records.img,
              display: records.img,
            },
            {
              type: 'evaluate',
              list: records.evaluate,
              display: true,
            },
          ].filter((e) => e.display),
        },
      ].filter((e) => e.display)
      return form
    },
    onRowSelect(records) {
      getAction('/base/farmEvaluate/detail', { id: records.id }, '/api').then((res) => {
        const data = this.getDrawerForm(res.data || records)
        apiTool.showDrawer({
          title: '详情',
          width: '700px',
          view: DrawerForm,
          viewProps: { data, form: records },
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        onSelectRow={this.onRowSelect}
        records={this.records}
      />
    )
  },
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.content-box {
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
